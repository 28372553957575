<template>
  <div>
    <div class="c-calendar-slider__wrapper u-width-720 o-content-box u-ml--a u-mr--a">
      <Slider
        class="c-calendar-slider"
        :value="value"
        :points="data"
        :data-value="'shortName'"
        :data-label="'longName'"
        :mark-as-link="true"
        :dot-with-icon="true"
        :base-url="baseUrl"
        :marks="true"
        :tooltip="'always'"
        @change="onChange"
      />
      <h2
        v-if="heading"
        class="u-font-size-h2 u-mt--l u-mt--l@mobile u-text-align--center"
      >
        {{ heading }}
      </h2>
      <p
        v-if="description"
        class="u-font-size-large u-text-align--center u-m--0"
      >
        {{ description }}
      </p>
    </div>
    <PublisherArticleGrid
      class="o-content-box"
      :model="modelGrid"
      :tag-uids="tagIds"
      order="alphabetical"
    />
  </div>
</template>

<script>
import Slider from '@/CVI/WebCore/components/Slider.vue'
import PublisherArticleGrid from '@/CVI/Publisher/components/ArticleGrid.vue'

export default {
  components: {
    Slider,
    PublisherArticleGrid
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    modelSlider: {
      type: Array,
      required: true
    },
    modelGrid: {
      type: Object,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: this.modelSlider,
      value: '',
      tagIds: [],
      cancelToken: null,
      heading: null,
      description: null
    }
  },
  mounted() {
    this.setValue()
  },
  methods: {
    setValue() {
      let month = null
      const currentMonth = this.data.find(obj => obj.isCurrent)
      if (currentMonth) {
        month = currentMonth
      } else {
        month = this.modelSlider[0]
      }

      const {
        shortName, tagIds, heading, description
      } = month

      this.value = shortName
      this.tagIds = tagIds
      this.heading = heading
      this.description = description
    },
    async onChange(value) {
      const {
        tagIds, slug, heading, description
      } = this.data.find(item => item.shortName === value)

      this.tagIds = tagIds
      this.heading = heading
      this.description = description

      this.updateUrl(slug)
    },
    updateUrl(url) {
      window.history.replaceState(null, null, `${this.baseUrl}${url}/`)
    }
  }
}
</script>
