<template>
  <vue-slider
    v-model="value"
    :data="points"
    :data-value="dataValue"
    :data-label="dataLabel"
    :marks="marks"
    :tooltip="tooltip"
    :dot-size="dotSize"
    :drag-on-click="false"
    :duration="0.1"
    :mark-as-link="markAsLink"
    :dot-with-icon="dotWithIcon"
    :base-url="baseUrl"
    @change="onChange(value)"
  >
    <template
      v-if="markAsLink"
      v-slot:mark="{ pos, label, value }"
    >
      <a
        :href="`${baseUrl}${getSlug(value)}/`"
        class="vue-slider-mark"
        :style="{ left: `${pos}%` }"
        @click.prevent
      >
        <div class="vue-slider-mark-step" />
        <div class="vue-slider-mark-label">
          {{ label }}
        </div>
      </a>
    </template>
    <template
      v-if="dotWithIcon"
      v-slot:dot="{ value }"
    >
      <div class="vue-slider-dot-handle">
        <img
          :class="{'invisible': !getDotIcon(value)}"
          :src="getDotIcon(value)" />
      </div>
    </template>
  </vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  components: {
    VueSlider
  },
  props: {
    points: {
      type: Array,
      required: true
    },
    dataValue: {
      type: String,
      required: true
    },
    dataLabel: {
      type: String,
      required: true
    },
    marks: {
      type: Boolean,
      default: true
    },
    tooltip: {
      type: String,
      default: 'active',
      required: false
    },
    dotSize: {
      type: Array,
      default: () => [32, 32],
      required: false
    },
    markAsLink: {
      type: Boolean,
      default: false,
      required: false
    },
    baseUrl: {
      type: String,
      default: '',
      required: false
    },
    dotWithIcon: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      value: this.points[0].shortName,
      selectedItem: this.points[0]
    }
  },
  mounted() {
    this.setValue()
  },
  methods: {
    setValue() {
      this.points.forEach((item) => {
        if (item.isCurrent) {
          this.value = item.shortName
          this.selectedItem = item
        }
      })
    },
    onChange() {
      this.$emit('change', this.value)
    },
    getSlug(value) {
      return this.points.find(item => item.shortName.toLowerCase() === value.toLowerCase()).slug
    },
    getDotIcon(value) {
      return this.points.find(item => item.shortName.toLowerCase() === value.toLowerCase()).iconUrl
    }
  }
}
</script>
